<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>Agregar nuevo precio</span>
        </div>

        <div class="pl-3 pt-3 pr-3 w-crud-form">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            v-model="form.name"
                            label="Nombre" 
                            outlined 
                            hide-details
                            :autofocus="true">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            v-model="form.price"
                            label="Precio" 
                            outlined 
                            hide-details
                            prefix="S/">
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Aceptar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: 'AddPrices',
    data () {
        return {
            drawer: false,
            form: {
                id: null,
                name: null,
                price: null
            }
        }
    },
    methods: {
        ...mapActions('products', [
            'addProductPrice'
        ]),
        show () {
            this.clearForm();
            this.drawer = !this.drawer;
        },
        clearForm () {
            this.form.id = null;
            this.form.name = null;
            this.form.price = null;
        },
        handleCancel () {
            this.clearForm();
            this.drawer = false;
        },
        closeForm () {
            this.drawer = false;
        },
        handleStore () {
            let today = new Date();
            this.form.id = today.getTime();
            let itemPrice = Object.assign({}, this.form);
            this.addProductPrice(itemPrice);
            this.closeForm();
        }
    }
}
</script>

<style scoped>

</style>